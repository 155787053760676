import React from 'react';
import { makeStyles } from '@material-ui/core';
import Marquee from 'react-fast-marquee';
import { brandsHomePage } from './data';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrowIcon } from '../../../images/svg/right-arrow-icon.svg';
import barImage from '../../../images/svg/bar.svg';
import barImage2 from '../../../images/svg/bar2.svg';

const useStyles = makeStyles((theme) => {
  return {
    brandsLogosList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      listStyle: 'none',
      padding: '0',
      gap: '40px',
    },
    brandImage: {
      maxWidth: '100%',
    },
    wrapper: {
      paddingTop: '25px',
    },
    listItem: {
      '&:last-child': {
        marginRight: '60px',
      },
    },
    logosHeading: {
      marginBottom: 0,
      padding: '0.5rem',
      backgroundImage: `url(${barImage2})`,
      backgroundSize: '100% 100%',
      textTransform: 'none',
      textAlign: 'center',
    },
    paragraph: {
      marginBottom: '3rem',
      fontSize: '1.25rem',
      fontWeight: 800,
      backgroundImage: `url(${barImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '4rem 1rem',
      backgroundPosition: '-0.15rem 0.75rem',
      position: 'relative',
    },
  };
});

const BrandsCircleLine = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className="container pt-4">
      <p className={classes.paragraph}>
        {t('Contact Information - Bodytext 1')}
        <RightArrowIcon className="right-quote-arrow" />
      </p>
      <div style={{ textAlign: 'center' }}>
        <div className={`h4 d-inline-block ${classes.logosHeading}`}>{t('Our Supporters')}</div>
      </div>
      <Marquee className={classes.wrapper}>
        <ul className={classes.brandsLogosList}>
          {brandsHomePage.map((brand, index) => (
            <li className={classes.listItem} key={index}>
              <img src={brand.img} alt="brand" style={{ width: brand.width }} className={classes.brandImage} />
            </li>
          ))}
        </ul>
      </Marquee>
    </div>
  );
};

export default BrandsCircleLine;
