import { Box, FormControlLabel, Modal, Typography, makeStyles, Checkbox, TextField, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import bagImage from './Luxury-Bag-Repair.png';
import { useForm } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'images/svg/checkbox-checked.svg';
import { Link } from '@reach/router';
import { getPage } from 'utils/pages';
import api from 'Club/api';
import CloseIcon from '@material-ui/icons/Close';
import orangeBg from './orange-background.svg';
import LoadingButton from 'components/LoadingButton';

type PageState = {
  state: '' | 'INVITED' | 'ERROR';
};

const BLOCKED_PATHS = {
  en: 'brand-partnerships',
  de: 'marken-partnerschaften',
};

interface PopUpWindowData {
  email: string;
  personalData: boolean;
}

const useStyles = makeStyles((theme) => {
  const s = (x: number) => theme.spacing(x);
  return {
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      height: '80%',
      maxWidth: 850,
      background: '#5bbccc',
      border: '7px solid #ffffff',
      padding: '15px',
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down(391)]: {
        height: '90%'
      },
      [theme.breakpoints.down(376)]: {
        height: '95%'
      },
    },
    img: {
      width: '100%',
      maxWidth: '100%',
      height: '400px',
    },
    columnsWrapper: {
      display: 'flex',
      height: '100%',
      gap: '25px',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
    },
    columnsSuccessWrapper: {
      display: 'flex',
      gap: '25px',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },
    formControlabel: {
      margin: 0,
      display: 'block',
    },
    checkBoxBlock: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      minHeight: '44px',
    },
    checkBoxText: {
      fontSize: '0.75rem!important',
      color: '#ffffff',
      fontFamily: 'Work Sans, sans-serif',
    },
    textField: {
      height: '40px',
      margin: '0 auto 20px',
      width: '260px',
      fontSize: '0.9rem',
      border: '1px solid #000000',
      '& input::placeholder': {
        color: '#000000',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',
        },
        '&:hover fieldset': {
          borderColor: '#000000',
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px!important',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#000000',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    leftBlock: {
      flex: '0 1 40%',
    },
    modalContent: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      gap: '26px',
      backgroundImage: `url(${bagImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '100%',
      width: '100%',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: '#ffffff',
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
    formFields: {
      display: 'flex',
      flexDirection: 'column',
      gap: '26px',
    },
    modalTitle: {
      fontSize: '30px',
      maxWidth: '330px',
      margin: '0 auto auto auto',
      paddingTop: '40px',
      color: '#000000',
      fontFamily: 'Work Sans, sans-serif',
      [theme.breakpoints.down(376)]: {
        fontSize: '26px',
      },
    },
    modalText: {
      textAlign: 'left',
      fontFamily: 'Work Sans, sans-serif',
      fontSize: '14px',
      padding: '0 10px 10px',
      [theme.breakpoints.down(365)]: {
        fontSize: '12px',
      },
    },
    submitButton: {
      width: '100%',
      fontFamily: 'Work Sans, sans-serif',
      border: 'none',
      textTransform: 'uppercase',
      maxWidth: '200px',
      fontSize: s(2),
      fontWeight: 700,
      color: '#ffffff',
      background: `url(${orangeBg})`,
      padding: `${s(0.8)}px ${s(0)}px`,
      transition: '0.4s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        scale: 1.1,
      },
    },
    link: {
      color: '#ffffff',
      transition: '0.4s ease-in-out',
      textTransform: 'uppercase',
      border: 'none',
      display: 'inline-block',
      padding: '5px 10px',
      background: '#EB672C',
      '&:hover': {
        color: '#ffffff',
        transform: 'scale(1.05)',
      },
    },
    checkInboxText: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '14px',
      margin: '10px auto',
      fontFamily: 'Work Sans, sans-serif',
    },
    discountText: {
      color: '#ffffff',
    },
  };
});

const PopUpMobleWindow = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalState, setModalState] = useState<PageState>({ state: '' });
  const [open, setOpen] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [hasShownModal, setHasShownModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PopUpWindowData>({
    email: '',
    personalData: false,
  });
  const { t } = useTranslation();

  const defaultValues: PopUpWindowData = {
    email: '',
    personalData: false,
  };

  const { register, watch, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchAllFields = watch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitForm = async (data) => {
    setData(data);
    setModalState({ state: 'INVITED' });
    setLoading(true);
    try {
      const { success } = await api.registerWithDiscount(data.email);
      if (!success) {
        // Handle error todo
      } else {
        // Handle success todo
      }
    } catch (err) {
      // Handle exception todo
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shouldShowModal = () => {
      const currentPath = window.location.pathname;
      return !Object.values(BLOCKED_PATHS).some((blockedPath) => currentPath.includes(blockedPath));
    };

    const handleClick = () => {
      if (isMobile && shouldShowModal()) {
        setClickCount((prevCount) => prevCount + 1);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isMobile]);

  useEffect(() => {
    if (clickCount === 2 && !hasShownModal) {
      handleOpen();
      setHasShownModal(true);
      setClickCount(0);
    }

    const timer = setTimeout(() => {
      setClickCount(0);
    }, 1000);

    return () => clearTimeout(timer);
  }, [clickCount, hasShownModal]);

  useEffect(() => {
    setSendDisabled(!watchAllFields.email || !watchAllFields.personalData);
  }, [watchAllFields]);

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modal}>
          <form onSubmit={handleSubmit(submitForm)} className={modalState.state === 'INVITED' ? classes.columnsSuccessWrapper : classes.columnsWrapper}>
            <div className={classes.modalContent}>
              <h2 className={classes.modalTitle} style={{ fontWeight: 'bolder' }}>
                {modalState.state === 'INVITED' ? t('YOUR INVITATION HAS BEEN SENT!') : t('MainModalTitle')}
              </h2>
              <button className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </button>
              {modalState.state === 'INVITED' || (
                <div className={classes.textField}>
                  <TextField
                    inputRef={register}
                    name="email"
                    variant="outlined"
                    placeholder="Email"
                    InputProps={{
                      style: {
                        color: 'white',
                      },
                    }}
                    fullWidth
                    autoComplete="email"
                  />
                </div>
              )}
            </div>
            <Box textAlign={'center'}>
              {modalState.state === 'INVITED' || (
                <>
                  <Typography component={'p'} className={classes.modalText}>
                    <Trans i18nKey="Modal Mobile Content Bottom" t={t} components={[<span className={classes.discountText} />]} />
                  </Typography>
                </>
              )}
              {modalState.state === 'INVITED' && (
                <Typography component={'p'} className={classes.checkInboxText}>
                  {t('Check your inbox for the invitation email')}
                </Typography>
              )}
              {modalState.state === 'INVITED' || (
                <div>
                  <Box mb="1rem" className={classes.formFields}>
                    <Box mb="1rem" className={classes.checkBoxBlock}>
                      <FormControlLabel
                        className={classes.formControlabel}
                        control={<Checkbox checkedIcon={<CheckedIcon />} />}
                        inputRef={register}
                        label
                        name="personalData"
                      />
                      <Box pl=".5rem" textAlign={'start'}>
                        <Typography className={classes.checkBoxText} variant="body2">
                          <Trans i18nKey="Modal Check Box Text" t={t} components={[<Link className="base-link" to={getPage('privacyPolicy')} />]} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              )}
              {modalState.state === 'INVITED' || (
                <LoadingButton loading={loading} type="submit" className={classes.submitButton} disabled={sendDisabled}>
                  {t('Registrieren')}
                </LoadingButton>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default PopUpMobleWindow;
